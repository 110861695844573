import { NgModule, APP_INITIALIZER, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { RouteReuseStrategy } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { merge } from 'rxjs';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { LayoutModule } from './layout/layout.module';
import { BackendService } from './core/backend.service';
import { LanguageLoader } from './core/language-loader';
import { ConfigService } from './core/config.service';
import { NcRouteReuseStrategy } from './core/route-reuse-strategy';
import { GlobalErrorHandler } from './core/global-error-handler';
import { setupInitialJwt } from 'src/app/helpers/jwt';
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';
import { environment } from '../environments/environment';
import * as Sentry from '@sentry/angular';
import { Router } from '@angular/router';
import { HttpResponseInterceptor } from './http-response.interceptor';
import { ValueCheckInformationComponent } from './my-account/value-check-information/value-check-information.component';
import { EditOrderPageComponent } from './my-account/edit-order-page-component/edit-order-page.component';
import { CookieConsentComponent } from './cookie-consent/cookie-consent.component';
import { KindlyChatBot } from './kindly-chatbot/kindly-chatbot.component';

@NgModule({
    declarations: [
        AppComponent,
        ValueCheckInformationComponent,
        EditOrderPageComponent,
        CookieConsentComponent,
        KindlyChatBot,
    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useClass: LanguageLoader,
                deps: [
                    BackendService
                ]
            }
        }),
        AppRoutingModule,
        SharedModule,
        LayoutModule,
        BrowserAnimationsModule,
        MatMomentDateModule,
        NgxGoogleAnalyticsModule.forRoot(environment.googleAnalyticsTrackingCode),
        NgxGoogleAnalyticsRouterModule,
    ],
    bootstrap: [AppComponent],
    providers: [
        {
            provide: ErrorHandler,
            useClass: GlobalErrorHandler,
        },

        // Sentry START
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({ showDialog: false }),
        },
        {
            provide: Sentry.TraceService,
            deps: [Router],
        },
        // Sentry END

        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpResponseInterceptor,
            multi: true
        },

        {
            provide: APP_INITIALIZER,
            useFactory: (configService: ConfigService, translate: TranslateService) => () => {
                setupInitialJwt();

                // Set initial translations
                translate.setTranslation('initial-se', {
                    'snack-bar.close': 'Stäng',
                    'snack-bar.default-error-message': 'Ett fel uppstod',
                });
                translate.use('initial-se');
                // Load some initial data needed on startup
                // The CurrencyPipe is dependent on it
                return merge(
                    configService.getConfig(),
                    configService.getCategories(),
                ).toPromise();
            },
            deps: [
                ConfigService,
                TranslateService,
                Sentry.TraceService, // Sentry
            ],
            multi: true,
        },
        {
            provide: RouteReuseStrategy,
            useClass: NcRouteReuseStrategy,
        },
        {
            provide: MAT_DATE_LOCALE,
            useValue: 'sv-SE',
        },
    ],
})
export class AppModule { }

