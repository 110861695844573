import { Component, OnInit, Renderer2 } from '@angular/core';

@Component({
  selector: 'nc-cookie-consent',
  templateUrl: './cookie-consent.component.html',
  styleUrls: ['./cookie-consent.component.scss']
})
export class CookieConsentComponent implements OnInit {

  constructor(private renderer: Renderer2) { }

  ngOnInit(): void {
    const bodyElement = document.getElementById('CookieDeclarationBody');
    const script = this.renderer.createElement('script');
    script.id = 'CookieDeclaration';
    script.type = 'text/javascript';
    script.src = 'https://consent.cookiebot.com/6f0cb36c-c518-4f4e-8e13-5e9636ff8786/cd.js';
    script.async = true;
    this.renderer.appendChild(bodyElement, script);
  }

}
